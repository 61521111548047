import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Box, Container, Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import Page from "src/components/Page";
import { Theme } from "src/theme";
import ReportsHeader from "../ReportsHeader";
import LineChart from "./LineChart";
import { StyledText } from "../../../components/StyledText";
import { getCurrentUserAccountId } from "../../../hooks/commonDataUtils";
import { getBifrost } from "../../../services/bifrost.service";
import RecentGamesTable from "../../games/components/RecentGamesTable";
import { ArrowRight as ArrowRightIcon } from "react-feather";
import moment from "moment-timezone";
import { ChartProps } from "./LineChart/Chart";
import { TouchableOpacity, View } from "react-native-web";
import { GameStatKeys, GENDERS, SoccerStatKeysObj, TeamStatKeys, TeamWithSquad } from "@ollie-sports/models";
import { COLORS, ObjectKeys, ObjectValues, api } from "@ollie-sports/core";
import { UnpackPromise } from "../../../utils/typescriptUtils";
import {
  GloballySelectedTeamsWithSquad,
  useGloballySelectedTeamsWithSquad
} from "../../../hooks/useGloballySelectedTeamsWithSquad";
import { CenteredLoader } from "../../../components/CenteredLoader";
import { useSessionPersistentState } from "../../../utils/usePersistentState";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const DashboardView: FC = () => {
  const globalTeams = useGloballySelectedTeamsWithSquad();

  if (!globalTeams) {
    return <CenteredLoader />;
  }

  return <DashboardViewInner globalTeams={globalTeams} />;
};

function DashboardViewInner(p: { globalTeams: GloballySelectedTeamsWithSquad }) {
  const classes = useStyles();
  const [selectedBucketSize, setSelectedBucketSize] = useSessionPersistentState<"week" | "month">({
    initialValue: "month",
    key: "dashboard-time-period-size"
  });

  const [startDateMS, setStartDateMS] = useSessionPersistentState({
    initialValue: moment().subtract(3, "M").valueOf(),
    key: "dashboard-start-date"
  });
  const [endDateMS, setEndDateMS] = useSessionPersistentState({
    initialValue: moment().valueOf(),
    key: "dashboard-end-datea"
  });
  const [isFetchingstats, setIsFetchingStats] = useState(false);

  const [teamAggData, setTeamAg] = useState<UnpackPromise<ReturnType<typeof api.getTeamAggregateStats>> | null>(null);
  const { setSelectedTeamsWithSquad, selectedTeamsWithSquad, allTeamsWithSquad } = p.globalTeams;

  const timezone = allTeamsWithSquad?.length ? allTeamsWithSquad?.[0].timezone : "US/Mountain";

  useEffect(() => {
    (async () => {
      if (!allTeamsWithSquad) {
        return;
      }

      setIsFetchingStats(true);
      const r1 = await getBifrost().getTeamAggregateStats.fetchServer({
        chunk: selectedBucketSize,
        endDateFilterMS: endDateMS,
        startOfWeek: "sunday",
        startDateFilterMS: startDateMS,
        teamIdsWithSquad: selectedTeamsWithSquad.map(t => t.teamIdWithSquad),
        stats: {
          [TeamStatKeys.tPossessionPerc]: true,
          [GameStatKeys.gDidWin]: true,
          [TeamStatKeys.tGoalDifferential]: true,
          [TeamStatKeys.tGoals]: true,
          [TeamStatKeys.tGoalsConceded]: true,
          [TeamStatKeys.tShotsOnTargetPerc]: true,
          [TeamStatKeys.tShotFinishingPerc]: true,
          [TeamStatKeys.tShots]: true,
          [TeamStatKeys.tShotsOnTarget]: true,
          [TeamStatKeys.tShotDifferential]: true
        },
        timezone
      });

      setTeamAg(r1.data);
      setIsFetchingStats(false);
    })();
  }, [
    !!allTeamsWithSquad,
    selectedBucketSize,
    startDateMS,
    endDateMS,
    selectedTeamsWithSquad
      .map(t => t.teamIdWithSquad)
      .sort()
      .join("")
  ]);

  const { data: recentGamesData } = getBifrost().soccerStatSnapshot__server__getSoccerGameEventAndSnapshotData.useServer(
    {
      offset: 0,
      pageSize: 4,
      teamIdsWithSquad: selectedTeamsWithSquad.map(t => t.teamIdWithSquad)
    },
    { skip: !allTeamsWithSquad }
  );

  const statData = teamAggData?.data ?? [];

  const dateFormat = selectedBucketSize === "week" ? "D MMM" : `MMM 'YY`;

  // TODO: Might want to revisit time zone stuff
  const labels = statData.map(data => moment.tz(data.dateStrAtStartOfChunkWithTZ, "UTC").format(dateFormat));

  const winPercentData = statData.map(data =>
    data.eventCount === 0 ? NaN : (data.stats.sum[GameStatKeys.gDidWin] ?? 0) / data.eventCount
  );

  const totalEventCount = statData.reduce((a, b) => a + (b.eventCount || 0), 0);

  const transformHoverLabel = (a: { label: string; index: number }) => {
    const count = eventCounts[a.index];
    if (!count) {
      return a.label;
    }
    return `${a.label} (${count} ${count === 1 ? "game" : "games"})`;
  };

  const generateTrendlineDataset = (pp: { statKey: TeamStatKeys | GameStatKeys; type: "avg" | "sum" }) => {
    let final: ChartProps["dataSets"][0] = {
      color: COLORS.grey_33,
      label: "trend",
      showPoints: false,
      data: [],
      showFill: false,
      showOnTooltipAndLegend: false
    };

    if (teamAggData?.trendLines) {
      const trendData = statData.map((d, index) => {
        if (index === 0) {
          return teamAggData.trendLines.start.stats[pp.type][pp.statKey] || 0;
        } else if (index === statData.length - 1) {
          return teamAggData.trendLines.stop.stats[pp.type][pp.statKey] || 0;
        } else {
          return Number.NaN;
        }
      });
      final.data = trendData;
    }

    return final;
  };

  if (!allTeamsWithSquad || !teamAggData) {
    return <CenteredLoader />;
  }

  const eventCounts = statData.map(data => data.eventCount);
  const maxEventsPerPoint = Math.max(...eventCounts);
  const pointRadii = statData.map(data => Math.max(12 * (data.eventCount / maxEventsPerPoint), 6));

  return (
    <Page className={classes.root}>
      <div style={{ position: "relative", top: -24, opacity: isFetchingstats ? 1 : 0 }}>
        <LinearProgress />
      </div>

      <Container maxWidth={false}>
        <ReportsHeader
          title="Dashboard"
          allTeamsWithSquad={allTeamsWithSquad}
          selectedTeamsWithSquad={selectedTeamsWithSquad}
          onSelectedTeamsWithSquad={newTeams => {
            setSelectedTeamsWithSquad(newTeams);
          }}
          selectedBucketSize={selectedBucketSize}
          onBucketSizeToggle={setSelectedBucketSize}
          startDateMS={startDateMS}
          onStartDateMSChange={setStartDateMS}
          endDateMS={endDateMS}
          onEndDateMSChange={setEndDateMS}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: winPercentData,
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  }
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="percent"
              title={"Win Percentage"}
              overallAvg={
                totalEventCount
                  ? statData.reduce((a, b) => a + (b.stats.sum[SoccerStatKeysObj.gDidWin] || 0), 0) / totalEventCount
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tPossessionPerc] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tPossessionPerc, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat={"percent"}
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tPossessionPerc]}
              title={"Possession Percent"}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box minWidth={400} maxWidth="100%">
              <RecentGamesTable gamesData={recentGamesData ?? []} condensed={true} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.sum[GameStatKeys.gDidWin] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: GameStatKeys.gDidWin, type: "sum" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="number"
              title={"Total Wins"}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary">
              Goals
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tGoalDifferential] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tGoalDifferential, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="number"
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tGoalDifferential]}
              title={"Goal Differential Per Game"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tGoals] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tGoals, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="number"
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tGoals]}
              title={"Goals Per Game"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tGoalsConceded] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tGoalsConceded, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="number"
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tGoalsConceded]}
              title={"Goals Against Per Game"}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" color="textPrimary">
              Shots
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tShotsOnTargetPerc] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tShotsOnTargetPerc, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="percent"
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tShotsOnTargetPerc]}
              title={"Shots on Goal Percent"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tShotFinishingPerc] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tShotFinishingPerc, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="percent"
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tShotFinishingPerc]}
              title={"Finishing Percentage"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tShots] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tShots, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="number"
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tShots]}
              title={"Shots Per Game"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tShotsOnTarget] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tShotsOnTarget, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="number"
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tShotsOnTarget]}
              title={"Shots on Goal Per Game"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LineChart
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: statData.map(d => (d.eventCount === 0 ? NaN : d.stats.avg[TeamStatKeys.tShotDifferential] || 0)),
                    showFill: true,
                    pointRadii,
                    transformHoverLabel,
                    showOnTooltipAndLegend: true
                  },
                  generateTrendlineDataset({ statKey: TeamStatKeys.tShotDifferential, type: "avg" })
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat="number"
              overallAvg={teamAggData?.overallAvgs.stats.avg[TeamStatKeys.tShotDifferential]}
              title={"Shot Differential Per Game"}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default DashboardView;
