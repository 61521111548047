import { Button, Container, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import { GameStatKeys, MATCH_TYPES, TeamStatKeys } from "@ollie-sports/models";
import MaterialTable from "material-table";
import moment from "moment-timezone";
import React, { FC, useState } from "react";
import { ArrowRight as ArrowRightIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";
import Page from "src/components/Page";
import { Theme } from "src/theme";
import { CenteredLoader } from "../../components/CenteredLoader";
import { GloballySelectedTeamsWithSquad, useGloballySelectedTeamsWithSquad } from "../../hooks/useGloballySelectedTeamsWithSquad";
import { getBifrost } from "../../services/bifrost.service";
import ReportsHeader from "./ReportsHeader";
import { useSessionPersistentState } from "../../utils/usePersistentState";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const ListTeamsWithStatsView: FC = () => {
  const globalTeams = useGloballySelectedTeamsWithSquad();

  if (!globalTeams) {
    return <CenteredLoader />;
  }

  return <ListTeamsWithStatsViewInner globalTeams={globalTeams} />;
};

function ListTeamsWithStatsViewInner(p: { globalTeams: GloballySelectedTeamsWithSquad }) {
  const classes = useStyles();

  const [startDateMS, setStartDateMS] = useSessionPersistentState({
    initialValue: moment().subtract(3, "M").valueOf(),
    key: "dashboard-start-date"
  });
  const [endDateMS, setEndDateMS] = useSessionPersistentState({
    initialValue: moment().valueOf(),
    key: "dashboard-end-datea"
  });
  const { setSelectedTeamsWithSquad, selectedTeamsWithSquad, allTeamsWithSquad } = p.globalTeams;
  const teamIdWithSquads = (selectedTeamsWithSquad || []).map(s => s.teamIdWithSquad);

  const [includeScrimmages, setIncludeScrimmages] = useSessionPersistentState({
    initialValue: true,
    key: "include-scrimmages"
  });

  const r1 = getBifrost().trends__server__getStatsForTeams.useServer(
    {
      matchTypes: includeScrimmages ? [MATCH_TYPES.game, MATCH_TYPES.scrimmage] : [MATCH_TYPES.game],
      teamIdWithSquads: teamIdWithSquads,
      endDateMS,
      startDateMS,
      // TODO Translate Web
      locale: "en-us"
    },
    { notifyOnMetaDataChanges: true }
  );

  if (!allTeamsWithSquad || !r1.data) {
    return <CenteredLoader />;
  }

  const data = r1.data.map(d => {
    const team_name = allTeamsWithSquad.find(x => x.teamIdWithSquad === d.teamIdWithSquad)?.teamNameWithSquad || "Unknown";
    const winPer = (d.stats.gameStatTotal[GameStatKeys.gDidWin] || 0) / d.stats.numGames;

    return {
      team_name,
      total_games: d.stats.numGames,
      teamIdWithSquad: d.teamIdWithSquad,
      avg_team_poss_perc: d.stats.teamStatAvg[TeamStatKeys.tPossessionPerc] || 0,
      avg_goals: d.stats.teamStatAvg[TeamStatKeys.tGoals] || 0,
      avg_goals_against: d.stats.teamStatAvg[TeamStatKeys.tGoalsConceded] || 0,
      avg_team_shot_diff: d.stats.teamStatAvg[TeamStatKeys.tShotDifferential] || 0,
      avg_shots_per_game: d.stats.teamStatAvg[TeamStatKeys.tShots] || 0,
      avg_shots_on_goal: d.stats.teamStatAvg[TeamStatKeys.tShotsOnTarget] || 0,
      win_per: winPer
    };
  });

  return (
    <Page className={classes.root}>
      <div style={{ position: "relative", top: -24, opacity: r1.isFetching ? 1 : 0 }}>
        <LinearProgress />
      </div>

      <Container maxWidth={false}>
        <ReportsHeader
          title="Team Trends"
          allTeamsWithSquad={allTeamsWithSquad}
          selectedTeamsWithSquad={selectedTeamsWithSquad}
          onSelectedTeamsWithSquad={newTeams => {
            setSelectedTeamsWithSquad(newTeams);
          }}
          startDateMS={startDateMS}
          onStartDateMSChange={setStartDateMS}
          endDateMS={endDateMS}
          onEndDateMSChange={setEndDateMS}
          includeScrimmages={includeScrimmages}
          setIncludeScrimmages={setIncludeScrimmages}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MaterialTable
              title="AVERAGES BY TEAM"
              columns={[
                { title: "Name", field: "team_name" },
                {
                  title: "Avg Possession",
                  field: "avg_team_poss_perc",
                  render: rowData => {
                    return <span>{(rowData.avg_team_poss_perc * 100).toFixed(2)}%</span>;
                  },
                  defaultSort: "desc"
                },
                { title: "Avg Goals", field: "avg_goals", render: rowData => <span>{rowData.avg_goals.toFixed(2)}</span> },
                {
                  title: "Avg Goals Against",
                  field: "avg_goals_against",
                  render: rowData => <span>{rowData.avg_goals_against.toFixed(2)}</span>
                },
                {
                  title: "Avg Shot Diff",
                  field: "avg_team_shot_diff",
                  render: rowData => <span>{rowData.avg_team_shot_diff.toFixed(2)}</span>
                },
                {
                  title: "Avg Shots",
                  field: "avg_shots_per_game",
                  render: rowData => <span>{rowData.avg_shots_per_game.toFixed(2)}</span>
                },
                {
                  title: "Avg Shots On Goal",
                  field: "avg_shots_on_goal",
                  render: rowData => <span>{rowData.avg_shots_on_goal.toFixed(2)}</span>
                },
                {
                  title: "Win Perc",
                  field: "win_per",
                  render: rowData => <span>{(rowData.win_per * 100).toFixed(2)}%</span>
                },
                {
                  title: "# Games",
                  field: "total_games"
                },
                {
                  title: "",
                  field: "teamIdWithSquad",
                  render: rowData => (
                    <RouterLink to={`/app/team/${rowData.teamIdWithSquad}/stats`}>
                      <Button color="primary" variant="contained" style={{ textTransform: "none" }} size="small">
                        <ArrowRightIcon color="#FFFFFF" />
                      </Button>{" "}
                    </RouterLink>
                  )
                }
              ]}
              data={data}
              options={{
                search: false,
                paging: false,
                columnResizable: true,
                headerStyle: { position: "sticky", top: 0 },
                maxBodyHeight: "calc(100vh - 350px)",
                toolbar: false,
                thirdSortClick: false
              }}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 20, color: "gray" }}>
          Note: Teams that do not have any games/scrimmages within the date range will be excluded.
        </div>
      </Container>
    </Page>
  );
}

export default ListTeamsWithStatsView;
