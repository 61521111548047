import React, { FC } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Typography, Box, Card, CardHeader, CardContent, Divider, makeStyles, useTheme } from "@material-ui/core";
import Chart, { ChartProps } from "./Chart";

interface LineChartProps {
  className?: string;
  overallAvg?: number;
  displayFormat: "number" | "percent";
  chartProps: Omit<ChartProps, "displayFormat">;
  title: string;
  cardHeaderActionNode?: JSX.Element & React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {},
  chart: {
    height: "100%"
  }
}));

const LineChart: FC<LineChartProps> = ({
  className,
  chartProps,
  title,
  overallAvg,
  displayFormat,
  cardHeaderActionNode,
  ...rest
}) => {
  const classes = useStyles();

  let avgO = "";

  if (overallAvg !== undefined) {
    avgO += `${formatChartNumber({ n: overallAvg, type: displayFormat })}`;
    if (displayFormat === "percent") {
      avgO += "%";
    }
    avgO += " avg";
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={cardHeaderActionNode ? cardHeaderActionNode : null}
        title={title}
        subheader={avgO}
        style={{ height: 73, alignItems: "flex-start" }}
      />

      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box height={150} width={"100%"}>
            <Chart
              className={classes.chart}
              showLegend={chartProps.showLegend}
              labels={chartProps.labels}
              dataSets={chartProps.dataSets}
              displayFormat={displayFormat}
            />
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

LineChart.propTypes = {
  className: PropTypes.string
};

export function formatChartNumber(p: { n: number; type: "number" | "percent" }) {
  let n = p.n;
  if (p.type === "percent") {
    n = n * 100;
  }
  return (Math.round(n * 100) / 100).toFixed(p.type === "number" ? 2 : 1);
}

export default LineChart;
