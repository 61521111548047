import React, { FC } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import { fade, makeStyles, useTheme } from "@material-ui/core";
import _ from "lodash";
import { Theme } from "src/theme";
import { COLORS } from "@ollie-sports/core";

export interface ChartProps {
  className?: string;
  dataSets: {
    showOnTooltipAndLegend: boolean;
    showPoints: boolean;
    label: string;
    color: COLORS;
    showFill: boolean;
    transformHoverLabel?: (a: {
      label: string;
      index: 0;
      value: string;
      x: number;
      xLabel: string;
      y: number;
      yLabel: number;
    }) => string;
    pointRadii?: number[];
    data: number[];
  }[];
  displayFormat: "number" | "percent";
  showLegend: boolean;
  labels: string[];
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative"
  }
}));

const Chart: FC<ChartProps> = ({ className, dataSets, labels, showLegend, displayFormat, ...rest }) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const dataSetIndexesToExcludeFromLegendAndTooptip: number[] = dataSets
    .map((ds, i) => (!ds.showOnTooltipAndLegend ? i : null))
    .filter((a): a is number => a !== null);

  const labelTransformersByDatasetIndex = _(dataSets).reduce((acc, ds, i) => {
    if (ds.transformHoverLabel) {
      acc[i] = ds.transformHoverLabel;
    }
    return acc;
  }, {} as Record<number, ChartProps["dataSets"][0]["transformHoverLabel"]>);

  const data = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Expected canvas context");
    }

    return {
      datasets: dataSets.map((ds, index) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);

        gradient.addColorStop(0, fade(ds.color, 0.2));
        gradient.addColorStop(0.9, "rgba(255,255,255,0)");
        gradient.addColorStop(1, "rgba(255,255,255,0)");

        if (!ds.showOnTooltipAndLegend) {
          dataSetIndexesToExcludeFromLegendAndTooptip.push(index);
        }
        const set = {
          data: ds.data.map(v => formatChartNumber({ n: v, type: displayFormat })),
          backgroundColor: gradient,
          fill: !!ds.showFill,
          spanGaps: true,
          borderColor: ds.color, // color of main line
          pointBorderColor: theme.palette.background.default,
          pointBorderWidth: 3,
          pointHoverRadius: ds.showPoints ? (ds.pointRadii ? ds.pointRadii : 6) : 0,
          pointRadius: ds.showPoints ? (ds.pointRadii ? ds.pointRadii : 6) : 0,
          label: ds.label,
          pointBackgroundColor: ds.color
        };
        return set;
      }),
      labels: labels
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      display: showLegend,
      position: "left",
      filter: (tooltipItem: any) => {
        return !dataSetIndexesToExcludeFromLegendAndTooptip.includes(tooltipItem.datasetIndex);
      }
    },
    layout: {
      padding: {
        top: 12
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            maxTicksLimit: 7,
            callback: (value: number) => (value > 0 ? `${value}` : value)
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: "index",
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.default,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      filter: (tooltipItem: any) => {
        return !dataSetIndexesToExcludeFromLegendAndTooptip.includes(tooltipItem.datasetIndex);
      },
      callbacks: {
        title: () => {},
        label: (tooltipItem: any) => {
          let label = `${tooltipItem.yLabel}${displayFormat === "percent" ? "%" : ""} - ${tooltipItem.xLabel}`;

          const labelTransformer = labelTransformersByDatasetIndex[tooltipItem.datasetIndex];

          if (labelTransformer) {
            label = labelTransformer({
              ...tooltipItem,
              label
            });
          }

          return label;
        }
      }
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Line data={data} options={options} />
    </div>
  );
};

export function formatChartNumber(p: { n: number; type: "number" | "percent" }) {
  let n = p.n;
  if (p.type === "percent") {
    n = n * 100;
  }
  return (Math.round(n * 100) / 100).toFixed(p.type === "number" ? 2 : 1);
}

export default Chart;
