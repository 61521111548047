import {
  AccountId,
  OrgId,
  OrgRegistration,
  OrgRegistrationPlayerSummary,
  OrgRegistrationTeamSummary,
  OrgSeasonId,
  TeamId
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import _ from "lodash";

export async function reports__server__getOrgRegistrationTeamSummary(p: {
  orgId: OrgId;
  teamId: TeamId;
  orgSeasonId: OrgSeasonId;
}): Promise<OrgRegistrationPlayerSummary[]> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  const res = await getAppPgPool().query(
    `select *
    from f_org_player_bundle_registration_summary($1, NULL)
    where team_id = $3
    and org_season_id = $2;`,
    [p.orgId, p.orgSeasonId, p.teamId]
  );

  return res.rows.map(row => {
    return {
      teamId: row["team_id"],
      playerBundleId: row["player_bundle_id"],
      teamName: row["team_name"],
      orgSeasonId: row["org_season_id"],
      orgId: row["org_id"],
      packageAmountCents: parseInt(row["package_amount_cents"]),
      lateFeeAmountPaidCents: parseInt(row["late_fee_amount_paid_cents"]),
      defaultPaidAmountCents: parseInt(row["default_paid_amount_cents"]),
      couponAmountCents: parseInt(row["coupon_amount_cents"]),
      creditAmountCents: parseInt(row["credit_amount_cents"]),
      totalDueCents: parseInt(row["total_due_cents"]),
      remainingDueCents: parseInt(row["remaining_due_cents"]),
      totalPaidIncludingCreditsCents: parseInt(row["total_paid_including_credits_cents"]),
      lineItems: row["line_items"] as OrgRegistration["relevantPackageInfoSnapshot"]["lineItems"],
      couponCode: row["coupon_code"]
    };
  });
  // SERVER_ONLY_TOGGLE
}

reports__server__getOrgRegistrationTeamSummary.auth = async (req: any) => {
  await validateToken(req);
};
