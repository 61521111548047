import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { KeyboardDatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  FormControlLabel,
  Typography,
  makeStyles,
  Theme,
  TextField,
  Drawer,
  Checkbox,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Calendar as CalendarIcon } from "react-feather";
import { getBifrost } from "../../services/bifrost.service";
import { RadioInputPillbox } from "../../components/RadioInputPillbox";
import { COLORS } from "../../theme/COLORS";
import { View } from "react-native-web";
import moment, { Moment } from "moment";
import FilterIcon from "@material-ui/icons/FilterList";
import { StyledText } from "../../components/StyledText";
import { TeamWithSquad } from "@ollie-sports/models";
import { TeamsWithSquadSelector } from "../../components/TeamsWithSquadSelector";
import { useCurrentUserTeamsAndOrgs } from "../../hooks/commonDataUtils";
import { useGlobalState } from "../../services/global-state.service";

interface ReportsHeaderProps {
  className?: string;
  title: string;
  onBucketSizeToggle?: (bucketSize: "week" | "month") => void;
  selectedBucketSize?: "week" | "month";
  allTeamsWithSquad?: TeamWithSquad[];
  selectedTeamsWithSquad?: TeamWithSquad[];
  onSelectedTeamsWithSquad?: (a: TeamWithSquad[]) => void;
  includeScrimmages?: boolean;
  setIncludeScrimmages?: (newVal: boolean) => void;
  onStartDateMSChange: (moment: number) => void;
  startDateMS: number;
  onEndDateMSChange: (moment: number) => void;
  endDateMS: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150
  }
}));

const ReportsHeader: FC<ReportsHeaderProps> = ({
  className,
  allTeamsWithSquad,
  onBucketSizeToggle,
  selectedBucketSize,
  startDateMS,
  onStartDateMSChange,
  endDateMS,
  onEndDateMSChange,
  selectedTeamsWithSquad,
  onSelectedTeamsWithSquad,
  includeScrimmages,
  setIncludeScrimmages,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} justifyContent="space-between" className={clsx(classes.root, className)} {...rest}>
      <Grid item>
        <h1 className="text-2xl sm:text-4xl mt-4">{title}</h1>
      </Grid>
      <Grid item style={{ width: "100%", marginBottom: 8 }}>
        <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
          <KeyboardDatePicker
            id="startDate"
            label="Start Date"
            format="YYYY/MM/DD"
            value={new Date(startDateMS)}
            onChange={newVal => {
              if (newVal?.valueOf()) {
                onStartDateMSChange(newVal?.valueOf());
              }
            }}
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            minDate={new Date("2000-01-01")}
            maxDate={moment(endDateMS).subtract(1, "d").toDate()}
          />
          <KeyboardDatePicker
            id="endDate"
            label="End Date"
            format="YYYY/MM/DD"
            value={new Date(endDateMS)}
            onChange={newVal => {
              if (newVal?.valueOf()) {
                onEndDateMSChange(newVal?.valueOf());
              }
            }}
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            minDate={moment(startDateMS).add(1, "d").toDate()}
          />

          {onBucketSizeToggle && selectedBucketSize ? (
            <RadioInputPillbox
              options={[
                {
                  label: "By Month",
                  value: "month"
                },
                {
                  label: "By Week",
                  value: "week"
                }
              ]}
              style={{ marginLeft: 8 }}
              onChange={o => onBucketSizeToggle(o as "week" | "month")}
              value={selectedBucketSize}
              unselectedTextColor={COLORS.grey_45}
              unselectedBackgroundColor={COLORS.grey_33}
              unselectedBorderColor={COLORS.grey_33}
              pillHeight={30}
            />
          ) : null}

          {setIncludeScrimmages ? (
            <FormControlLabel
              style={{ fontSize: 100 }}
              control={
                <Checkbox
                  checked={includeScrimmages}
                  onChange={e => {
                    setIncludeScrimmages(e.target.checked);
                  }}
                  name="checkedB"
                  color="secondary"
                />
              }
              label="Include Scrimmages"
            />
          ) : null}

          {allTeamsWithSquad && onSelectedTeamsWithSquad && selectedTeamsWithSquad ? (
            <View style={{ marginLeft: "auto" }}>
              <TeamsWithSquadSelector
                allTeamsWithSquad={allTeamsWithSquad}
                onSelectedTeamsWithSquad={onSelectedTeamsWithSquad}
                selectedTeamsWithSquad={selectedTeamsWithSquad}
              />
            </View>
          ) : null}
        </View>
      </Grid>
    </Grid>
  );
};

ReportsHeader.propTypes = {
  className: PropTypes.string
};

export default ReportsHeader;
