import {
  AccountId,
  OrgId,
  OrgRegistrationAnswer,
  OrgRegistrationAnswerTableData,
  OrgRegistrationQuestionType,
  OrgSeasonId,
  PlayerBundleId
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import { ObjectKeys, shouldSendEmail } from "../../utils";
import _ from "lodash";
import { reports__server__getOrgRegistrationAnswers } from "./reports__getOrgRegistrationAnswers";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import moment from "moment";

export async function reports__server__exportOrgRegistrationAnswers(p: {
  orgId: OrgId;
  orgSeasonId: OrgSeasonId;
  playerBundleIds: PlayerBundleId[];
  selfEmail: string;
  selfAccountId: string;
}) {
  // SERVER_ONLY_TOGGLE
  const {
    getAppPgPool,
    appOllieFirestoreV2: h,
    injectedServerLibraries: { sendGrid }
  } = getServerHelpers();
  const allResults = await reports__server__getOrgRegistrationAnswers({
    limit: 10000,
    offset: 0,
    orgId: p.orgId,
    orgSeasonId: p.orgSeasonId,
    sort: [{ key: "created_at_ms", dir: "desc" }]
  });

  const accountPrivate = await h.AccountPrivate.getDoc(p.selfAccountId);

  const filteredResults = allResults.data.filter(a => p.playerBundleIds.includes(a.playerBundleId));

  const allShortTitles = filteredResults.reduce((acc, val) => {
    val.answers.forEach(answer => {
      acc[answer.questionSnapshot.shortTitle] = true;
    });
    return acc;
  }, {} as Record<string, true>); // Record<title, ids with that title

  const locale = accountPrivate?.communicationLocale ?? "en-us";
  const subject = translate({ defaultMessage: `Registration Responses`, serverLocale: locale });
  let exportString = `${translate.common(locale).FirstName},${translate.common(locale).LastName},${
    translate.common(locale).Team
  },${translate.common(locale).Date}${Object.keys(allShortTitles)
    ?.map(customSectionTitle => {
      return `,${customSectionTitle}`;
    })
    .join("")}\n`;

  filteredResults.forEach(r => {
    exportString += `${r.firstName},${r.lastName},${r.teamName},${dateFormatters.mm_dd_yyyy(
      moment(r.createdAtMS),
      locale
    )}${Object.keys(allShortTitles)
      ?.map(shortTitle => {
        const answer = r.answers.find(ans => {
          return ans.questionSnapshot.shortTitle === shortTitle;
        });
        if (answer) {
          let responseString = "";
          if (answer) {
            switch (answer.type) {
              case OrgRegistrationQuestionType.checkbox:
                responseString = answer.selections
                  .map(s => {
                    return s.replace(/\n/g, ` `).replace(/,/g, ` `);
                  })
                  .join(":");
                break;
              case OrgRegistrationQuestionType.radio:
                responseString = answer.selection.replace(/\n/g, ` `).replace(/,/g, ` `);
                break;
              case OrgRegistrationQuestionType.freeResponse:
                responseString = answer.response.replace(/\n/g, ` `).replace(/,/g, ` `);
                break;
              case OrgRegistrationQuestionType.legalDoc:
                responseString = `${moment(answer.agreedToAtMS).format("MM/DD/YYYY")}`;
                break;
            }
          }
          return `,${responseString}`;
        }
        return "";
      })
      .join("")}\n`;
  });

  if (shouldSendEmail(p.selfEmail)) {
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: p.selfEmail,
      subject,
      ipPoolName: "Transactional",
      html: `
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8">
    <title>${translate({ defaultMessage: "Registration Responses", serverLocale: locale })}</title>
    <meta name="description" content="${subject}">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    </head>
    <body>
    <p>${translate({
      defaultMessage: "Attached to this email you will find your exported registration responses",
      serverLocale: locale
    })}.</p>
    </body>
    </html>
    `,
      attachments: [
        {
          content: Buffer.from(exportString).toString("base64"),
          filename: `registration-responses.csv`,
          type: "application/csv",
          disposition: "attachment",
          contentId: "registrationResponses"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${p.selfEmail} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }

  // SERVER_ONLY_TOGGLE
}

reports__server__exportOrgRegistrationAnswers.auth = async (req: any) => {
  await validateToken(req);
};
