import {
  AccountId,
  OrgId,
  OrgRegistration,
  OrgRegistrationTeamSummary,
  OrgSeasonId,
  Team__StaffTypes
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import _ from "lodash";
import { player__server__getPrettyPlayers } from "../player/player__getPrettyPlayers";

export async function reports__server__getOrgAccountsReport(p: { orgId: OrgId }) {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();

  const teams = (
    await h.Team.query({
      where: [
        {
          orgId: ["==", p.orgId]
        }
      ]
    })
  ).docs.filter(t => t.deletedAtMS === 0);

  const rowData: {
    name: string;
    headCoachName: string;
    numPlayers: number;
    numStaff: number;
    totalAccounts: number;
    orgTeamTagIds?: Record<string, true>;
    birthYear: string;
  }[] = [];

  // Process each chunk of teams and accumulate results in rowData
  for (const chunk of _.chunk(teams, 500)) {
    const teamDataPromises = chunk.map(async team => {
      const headCoachAccountId = Object.keys(team.accounts).find(
        aid => team.accounts[aid]?.staffTitle === Team__StaffTypes.headCoach
      );

      const headCoachAccount = headCoachAccountId ? await h.Account.getDoc(headCoachAccountId) : undefined;

      const playerIds = Object.keys(team.derived.activePlayerIds);

      const prettyPlayers = await player__server__getPrettyPlayers({ playerIds });

      const numberPlayersLinked = prettyPlayers?.filter(pp => pp.derived.accountInfoSource !== "player").length ?? 0;
      const numberStaffLinked = Object.keys(team.accounts).filter(aid => !!team.accounts[aid]?.staffTitle).length;

      return {
        headCoachName: headCoachAccount ? `${headCoachAccount.firstName} ${headCoachAccount.lastName}` : "",
        name: team.name,
        numPlayers: numberPlayersLinked,
        numStaff: numberStaffLinked,
        totalAccounts: Object.keys(team.accounts).length,
        orgTeamTagIds: team.assignedOrgTagIds,
        birthYear: team.birthYear ?? ""
      };
    });

    const chunkResults = await Promise.all(teamDataPromises);
    rowData.push(...chunkResults);
  }

  return rowData;
  // SERVER_ONLY_TOGGLE
}

reports__server__getOrgAccountsReport.auth = async (req: any) => {
  await validateToken(req);
};
