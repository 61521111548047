import {
  ButtonBase,
  Checkbox,
  Container,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  makeStyles
} from "@material-ui/core";
import { LaunchOutlined } from "@material-ui/icons";
import { COLORS, getTeamSoccerStatDefs, api } from "@ollie-sports/core";
import { MATCH_TYPES, SoccerStatSnapshot, TeamStatKeys } from "@ollie-sports/models";
import MaterialTable from "material-table";
import moment from "moment-timezone";
import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import { Theme } from "src/theme";
import { CenteredLoader } from "../../components/CenteredLoader";
import { GloballySelectedTeamsWithSquad, useGloballySelectedTeamsWithSquad } from "../../hooks/useGloballySelectedTeamsWithSquad";
import { getBifrost } from "../../services/bifrost.service";
import { ActivityWidgetHacky } from "../stats/StatsView/components/ActivityWidgetHacky";
import LineChart from "./DashboardView/LineChart";
import ReportsHeader from "./ReportsHeader";
import { useCurrentUserAccountPrivate, useCurrentUserTeamsAndOrgs } from "../../hooks/commonDataUtils";
import { useSessionPersistentState } from "../../utils/usePersistentState";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const TeamStatsView: FC = () => {
  const globalTeams = useGloballySelectedTeamsWithSquad();
  const { data: userTeamsAndOrgs } = useCurrentUserTeamsAndOrgs();

  const ap = useCurrentUserAccountPrivate();

  const { teamIdWithSquad } = useParams<any>();

  if (!globalTeams) {
    return <CenteredLoader />;
  }

  const t1 = globalTeams.allTeamsWithSquad?.find(t => t.teamIdWithSquad === teamIdWithSquad);

  const hasAccountLicense = !!ap.data?.linkedLicenseId;
  const hasTeamHasLicense = !!t1?.linkedLicenseId;
  let isOrgAdmin = false;
  if (t1?.orgId) {
    const teamOrg = userTeamsAndOrgs?.orgs.find(o => o.id === t1.orgId);
    if (teamOrg && ap.data) {
      if (teamOrg.accounts[ap.data.id]) {
        isOrgAdmin = true;
      }
    }
  }

  const canAccessContent = hasAccountLicense || hasTeamHasLicense || isOrgAdmin;

  if (!canAccessContent) {
    return (
      <div style={{ margin: 30 }}>
        <h2>Upgrade to Ollie Premium</h2>
        <p style={{ marginTop: 20 }}> Team trends is a premium feature. Please upgrade to Ollie Premium to access the content!</p>

        <div>
          <img
            style={{ width: "40%", margin: 20 }}
            src="https://firebasestorage.googleapis.com/v0/b/ollie-app-95ed8.appspot.com/o/public%2Fteam-trends-example-1.png?alt=media"
          />
          <img
            style={{ width: "40%", margin: 20 }}
            src="https://firebasestorage.googleapis.com/v0/b/ollie-app-95ed8.appspot.com/o/public%2Fteam-trends-example-2.png?alt=media"
          />
        </div>
      </div>
    );
  }

  return <TeamStatsViewInnter globalTeams={globalTeams} teamIdWithSquad={teamIdWithSquad} />;
};

function TeamStatsViewInnter(p: { globalTeams: GloballySelectedTeamsWithSquad; teamIdWithSquad: string }) {
  const [hiddenGames, setHiddenGames] = useState<any>({});
  const [stat1Key, setStat1Key] = useState(TeamStatKeys.tPossessionPerc);
  const [stat2Key, setStat2Key] = useState(TeamStatKeys.tShots);
  const [stat3Key, setStat3Key] = useState(TeamStatKeys.tShotsOnTarget);

  const classes = useStyles();

  const [startDateMS, setStartDateMS] = useSessionPersistentState({
    initialValue: moment().subtract(3, "M").valueOf(),
    key: "dashboard-start-date"
  });
  const [endDateMS, setEndDateMS] = useSessionPersistentState({
    initialValue: moment().valueOf(),
    key: "dashboard-end-datea"
  });

  const [includeScrimmages, setIncludeScrimmages] = useSessionPersistentState({
    initialValue: true,
    key: "include-scrimmages"
  });

  const d1 = getBifrost().trends__server_teamPage1.useServer(
    {
      teamIdWithSquad: p.teamIdWithSquad,
      endDateMS,
      startDateMS,
      matchTypes: includeScrimmages ? [MATCH_TYPES.game, MATCH_TYPES.scrimmage] : [MATCH_TYPES.game]
    },
    { notifyOnMetaDataChanges: true }
  );

  if (!d1.data) {
    return <CenteredLoader />;
  }

  const filteredData = d1.data.filter((d: any) => {
    return !hiddenGames[d.snapshot.id];
  });

  const labels = filteredData.map((d: any) => {
    return d.opp_name + ` (${d.own_team_score}-${d.opp_team_score})`;
  });

  const stat1Data = filteredData.map((d: any) => {
    const snapShot: SoccerStatSnapshot = d.snapshot;
    return snapShot.teamStats.ownTeam[stat1Key];
  });

  const stat2Data = filteredData.map((d: any) => {
    const snapShot: SoccerStatSnapshot = d.snapshot;
    return snapShot.teamStats.ownTeam[stat2Key];
  });

  const stat3Data = filteredData.map((d: any) => {
    const snapShot: SoccerStatSnapshot = d.snapshot;
    return snapShot.teamStats.ownTeam[stat3Key];
  });

  const columns: any = [{ title: "Stat", field: "stat" }];
  const tableData: any = [];

  labels.forEach((l: any, index: any) => {
    columns.push({
      title: l,
      field: `${index}`
    });
  });

  const temp1: any[] = [];

  // TODO Translate Web
  Object.keys(getTeamSoccerStatDefs("en-us")).forEach(key => {
    const row: any = {};

    // @ts-ignore
    if (getTeamSoccerStatDefs("en-us")[key].prettyShortName && getTeamSoccerStatDefs("en-us")[key].prettyShortName.length > 1) {
      // @ts-ignore
      temp1.push(<MenuItem value={key}>{getTeamSoccerStatDefs("en-us")[key].prettyShortName}</MenuItem>);
    }

    // @ts-ignore
    if (!getTeamSoccerStatDefs("en-us")[key].prettyShortName) {
      return;
    }
    // @ts-ignore
    row["stat"] = getTeamSoccerStatDefs("en-us")[key].prettyShortName;
    // @ts-ignore
    let stringFormatHint = getTeamSoccerStatDefs("en-us")[key].stringFormatHint;
    filteredData.forEach((d: any, index: any) => {
      let value = d.snapshot.teamStats.ownTeam[key];
      if (value === undefined) {
        value = "-";
      } else if (stringFormatHint === "percent") {
        value = `${(value * 100).toFixed(2)}%`;
      } else if (typeof value === "number" && !Number.isInteger(value)) {
        value = value.toFixed(2);
      }

      row[`${index}`] = value;
    });

    tableData.push(row);
  });

  const teamName =
    p.globalTeams.allTeamsWithSquad?.find(t => t.teamIdWithSquad === p.teamIdWithSquad)?.teamNameWithSquad || "Unknown";
  return (
    <Page className={classes.root} title={teamName}>
      <div style={{ position: "relative", top: -24, opacity: d1.isFetching ? 1 : 0 }}>
        <LinearProgress />
      </div>

      <Container maxWidth={false}>
        <ReportsHeader
          title={teamName}
          startDateMS={startDateMS}
          onStartDateMSChange={setStartDateMS}
          endDateMS={endDateMS}
          onEndDateMSChange={setEndDateMS}
          includeScrimmages={includeScrimmages}
          setIncludeScrimmages={setIncludeScrimmages}
        />
        <Grid container spacing={3}>
          <Grid item xs={9} md={9}>
            <LineChart
              cardHeaderActionNode={
                <div>
                  <Select
                    value={stat1Key as any}
                    onChange={e => {
                      setStat1Key(e.target.value as any);
                    }}
                  >
                    {temp1}
                  </Select>
                </div>
              }
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: stat1Data as any,
                    showFill: true,
                    showOnTooltipAndLegend: true
                  }
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat={getTeamSoccerStatDefs("en-us")[stat1Key].formatCategory === "percent" ? "percent" : "number"}
              title={getTeamSoccerStatDefs("en-us")[stat1Key].prettyName}
              overallAvg={stat1Data.reduce((a: any, v: any) => a + v, 0) / stat1Data.length}
            />
            <div style={{ marginTop: 10 }} />
            <LineChart
              cardHeaderActionNode={
                <div>
                  <Select
                    value={stat2Key as any}
                    onChange={e => {
                      setStat2Key(e.target.value as any);
                    }}
                  >
                    {temp1}
                  </Select>
                </div>
              }
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: stat2Data as any,
                    showFill: true,
                    showOnTooltipAndLegend: true
                  }
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat={getTeamSoccerStatDefs("en-us")[stat2Key].formatCategory === "percent" ? "percent" : "number"}
              title={getTeamSoccerStatDefs("en-us")[stat2Key].prettyName}
              overallAvg={stat2Data.reduce((a: any, v: any) => a + v, 0) / stat2Data.length}
            />
            <div style={{ marginTop: 10 }} />
            <LineChart
              cardHeaderActionNode={
                <div>
                  <Select
                    value={stat3Key as any}
                    onChange={e => {
                      setStat3Key(e.target.value as any);
                    }}
                  >
                    {temp1}
                  </Select>
                </div>
              }
              chartProps={{
                dataSets: [
                  {
                    color: COLORS.orange,
                    label: "na",
                    showPoints: true,
                    data: stat3Data as any,
                    showFill: true,
                    showOnTooltipAndLegend: true
                  }
                ],
                labels: labels,
                showLegend: false
              }}
              displayFormat={getTeamSoccerStatDefs("en-us")[stat3Key].formatCategory === "percent" ? "percent" : "number"}
              title={getTeamSoccerStatDefs("en-us")[stat3Key].prettyName}
              overallAvg={stat3Data.reduce((a: any, v: any) => a + v, 0) / stat3Data.length}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <List component="div" disablePadding style={{ paddingBottom: 10 }}>
              {d1.data.map((t: any, index: any) => {
                let m1 = moment(parseInt(t.date_of_event_ms)).format("MM/DD");
                return (
                  <ListItem style={{ padding: 0, textAlign: "left" }} key={index}>
                    <ButtonBase
                      onClick={() => {
                        setHiddenGames({ ...hiddenGames, ...{ [t.snapshot.id]: !hiddenGames[t.snapshot.id] } });
                      }}
                      style={{ width: "100%" }}
                    >
                      <Checkbox checked={!hiddenGames[t.snapshot.id]} />
                      <ListItemText style={{ textAlign: "left" }}>
                        {t.opp_name} <span style={{ fontSize: "60%", color: "#888" }}>({m1})</span>
                        <span
                          style={{ position: "relative", top: 4, paddingLeft: 5 }}
                          onClick={e => {
                            /*eslint no-restricted-globals: ["error", "event"]*/
                            const root = location.protocol + "//" + location.host;
                            window.open(`${root}/stats/${t.snapshot.calendarEntryId}`);
                            e.stopPropagation();
                          }}
                        >
                          <LaunchOutlined fontSize="inherit" color={"secondary"} />
                        </span>
                      </ListItemText>
                    </ButtonBase>
                  </ListItem>
                );
              })}
            </List>
            <span style={{ color: "gray", fontSize: 10, paddingLeft: 15 }}>Max of 20 games displayed</span>
          </Grid>

          <Grid item xs={12} md={12}>
            <div
              style={{
                maxWidth: "calc(100vw - 340px)",
                overflowY: "scroll",
                marginBottom: 100
              }}
            >
              <MaterialTable
                columns={columns}
                data={tableData}
                options={{
                  search: false,
                  sorting: false,
                  paging: false,
                  overflowY: "scroll",
                  headerStyle: { position: "sticky", top: 0 },
                  maxBodyHeight: "calc(100vh - 200px)",
                  toolbar: false
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default TeamStatsView;
