import { AccountId, OrgId, OrgRegistration, OrgRegistrationTeamSummary, OrgSeasonId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import _ from "lodash";

export async function reports__server__getMoneyOwedForOrg(p: { orgId: OrgId }) {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();
  const data = await getAppPgPool().query(
    `
      with scheduled_registration_payments as (
    select sum((item ->> 'amountDueCents')::numeric - (item ->> 'derivedTotalAmountPaidCentsBeforeAllFees')::numeric) as sum
    from mirror_orginvoice
    where item ->> 'orgId' = $1
      and item ->> 'thisInvoicePaidInFullDateMS' = '0'
      and string_ms_to_timestamp(item ->> 'dueDateMS') >= now()
      and item ->> 'type' in ('registration', 'registration-payment-plan-installment')
),
     not_yet_registered as (
         select sum((orp.item ->> 'amountCents')::numeric) as sum
         from f_player_bundle_registration_status($1, null) s,
              mirror_orgregistrationpackage orp
         where s.prioritized_registration_package_id = orp.id
           and status = 'unregistered'
     ),
     past_due_registration_payment_plans as (
         select sum((item ->> 'amountDueCents')::numeric -
                    (item ->> 'derivedTotalAmountPaidCentsBeforeAllFees')::numeric) as sum
         from mirror_orginvoice
         where item ->> 'orgId' = $1
           and item ->> 'thisInvoicePaidInFullDateMS' = '0'
           and string_ms_to_timestamp(item ->> 'dueDateMS') < now()
           and item ->> 'type' in ('registration', 'registration-payment-plan-installment')
     ),
     past_due_manual_invoices as (
         select sum((item ->> 'amountDueCents')::numeric -
                    (item ->> 'derivedTotalAmountPaidCentsBeforeAllFees')::numeric) as sum
         from mirror_orginvoice
         where item ->> 'orgId' = $1
           and item ->> 'thisInvoicePaidInFullDateMS' = '0'
           and string_ms_to_timestamp(item ->> 'dueDateMS') < now()
           and item ->> 'type' in ('manual', 'manual-payment-plan-installment')
     ),
     pending_manual_invoices as (
         select sum((item ->> 'amountDueCents')::numeric -
                    (item ->> 'derivedTotalAmountPaidCentsBeforeAllFees')::numeric) as sum
         from mirror_orginvoice
         where item ->> 'orgId' = $1
           and string_ms_to_timestamp(item ->> 'dueDateMS') >= now()
           and item ->> 'thisInvoicePaidInFullDateMS' = '0'
           and item ->> 'type' in ('manual', 'manual-payment-plan-installment')
     )
select scheduled_registration_payments.sum     as scheduled_registration_payments,
       not_yet_registered.sum                  as not_yet_registered,
       past_due_registration_payment_plans.sum as past_due_registration_payment_plans,
       past_due_manual_invoices.sum            as past_due_manual_invoices,
       pending_manual_invoices.sum             as not_yet_due_manual_invoices
from scheduled_registration_payments,
     not_yet_registered,
     past_due_registration_payment_plans,
     past_due_manual_invoices,
     pending_manual_invoices
`,
    [p.orgId]
  );

  const scheduledRegistrationPayments = parseFloat(data.rows[0]["scheduled_registration_payments"] ?? "0");
  const notYetRegistered = parseFloat(data.rows[0]["not_yet_registered"] ?? "0");
  const pastDueRegistrationPaymentPlans = parseFloat(data.rows[0]["past_due_registration_payment_plans"] ?? "0");
  const pastDueManualInvoices = parseFloat(data.rows[0]["past_due_manual_invoices"] ?? "0");
  const notYetDueDueManualInvoices = parseFloat(data.rows[0]["not_yet_due_manual_invoices"] ?? "0");

  return {
    scheduledRegistrationPayments,
    notYetRegistered,
    pastDueRegistrationPaymentPlans,
    pastDueManualInvoices,
    notYetDueDueManualInvoices,
    total:
      scheduledRegistrationPayments +
      notYetRegistered +
      pastDueRegistrationPaymentPlans +
      pastDueManualInvoices +
      notYetDueDueManualInvoices
  };
  // SERVER_ONLY_TOGGLE
}

reports__server__getMoneyOwedForOrg.auth = async (req: any) => {
  await validateToken(req);
};
