import { AccountId, OrgId, OrgRegistration, OrgRegistrationTeamSummary, OrgSeasonId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import _ from "lodash";

export async function reports__server__getOrgRegistrationSummaryByTeam(p: {
  orgId: OrgId;
  orgSeasonId: OrgSeasonId;
}): Promise<OrgRegistrationTeamSummary[]> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  const res = await getAppPgPool().query(
    `select *
  from f_org_team_registration_summary($1, NULL)
  where org_season_id = $2;`,
    [p.orgId, p.orgSeasonId]
  );

  return res.rows.map(row => {
    const couponCodeData = row["coupon_codes"] as string;
    const couponCodesWithUsages = couponCodeData
      ? _.split(couponCodeData, "|").reduce((acc, val) => {
          if (acc[val]) {
            acc[val] = acc[val] + 1;
          } else {
            acc[val] = 1;
          }
          return acc;
        }, {} as Record<string, number>)
      : {};
    return {
      teamId: row["team_id"],
      teamName: row["team_name"],
      orgSeasonId: row["org_season_id"],
      orgId: row["org_id"],
      packageAmountCents: parseInt(row["package_amount_cents"]),
      lateFeeAmountPaidCents: parseInt(row["late_fee_amount_paid_cents"]),
      defaultPaidAmountCents: parseInt(row["default_paid_amount_cents"]),
      couponAmountCents: parseInt(row["coupon_amount_cents"]),
      creditAmountCents: parseInt(row["credit_amount_cents"]),
      totalDueCents: parseInt(row["total_due_cents"]),
      remainingDueCents: parseInt(row["remaining_due_cents"]),
      totalPaidIncludingCreditsCents: parseInt(row["total_paid_including_credits_cents"]),
      lineItems: row["line_items"] as OrgRegistration["relevantPackageInfoSnapshot"]["lineItems"][],
      numPlayers: row["num_players"] as number,
      couponCodesWithUsages
    };
  });
  // SERVER_ONLY_TOGGLE
}

reports__server__getOrgRegistrationSummaryByTeam.auth = async (req: any) => {
  await validateToken(req);
};
