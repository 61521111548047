import { AccountId, OrgId, OrgRegistrationAnswer, OrgRegistrationAnswerTableData, OrgSeasonId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import { ObjectKeys } from "../../utils";
import _ from "lodash";

type SortKeys = "name" | "created_at_ms" | "team_name";

const sortKeys: Record<SortKeys, true> = {
  name: true,
  created_at_ms: true,
  team_name: true
};

export async function reports__server__getOrgRegistrationAnswers(p: {
  orgId: OrgId;
  orgSeasonId: OrgSeasonId;
  offset: number;
  limit: number;
  search?: string;
  sort?: {
    key: SortKeys;
    dir: "desc" | "asc";
  }[];
}): Promise<{ data: OrgRegistrationAnswerTableData[]; count: number }> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  const searchTerm = p.search ? `%${p.search?.replace(/ /g, "").toLowerCase()}%` : "";

  const baseParam = {
    orgId: { valid: true, val: p.orgId },
    orgSeasonId: { valid: true, val: p.orgSeasonId },
    offset: { valid: true, val: p.offset || 0 },
    limit: { valid: true, val: p.limit || 100 }
  };

  const queryParams = _(baseParam)
    .entries()
    .filter(a => !!a[1].valid)
    .map((a, i) => {
      return [a[0], { sqlParam: `$${i + 1}`, value: a[1].val }];
    })
    .fromPairs()
    .value() as { [k in keyof typeof baseParam]?: { sqlParam: string; value: any } };

  const validFields = ObjectKeys(sortKeys);

  const validDirections = ["asc", "desc"];

  let sort = (p.sort || [])
    .filter(a => validFields.includes(a.key) && validDirections.includes(a.dir))
    .map(a => ({ ...a, key: _.snakeCase(a.key) }));

  if (sort.length === 0) {
    sort.push({ key: "name", dir: "asc" });
  }

  const orderBy = sort
    .map(a => {
      if (a.key === "name") {
        return `trim(concat(first_name, ' ', last_name)) ` + a.dir;
      } else {
        return `${a.key} ${a.dir}`;
      }
    })
    .join(", ");

  const res = await getAppPgPool().query(
    `select player_bundle_id, first_name, last_name, json_agg(answer) as answers, cast(created_at_ms as numeric), team_name,
    count(*) OVER () AS count
    from (select b.*, ans.item as answer
          from (select (jsonb_each(a.answer_ids)).key as answer_id,
                       a.player_bundle_id,
                       a.first_name,
                       a.last_name,
                       a.created_at_ms,
                       a.team_name
                from (select reg.item ->> 'playerBundleId'                      as player_bundle_id,
                             reg.item ->> 'createdAtMS'                         as created_at_ms,
                             reg.item -> 'answerIds'                            as answer_ids,
                             pb.item -> 'virtualAthleteAccount' ->> 'firstName' as first_name,
                             pb.item -> 'virtualAthleteAccount' ->> 'lastName'  as last_name,
                             t.item ->> 'name' as team_name
                      from mirror_orgregistration reg,
                           mirror_playerbundle pb,
                           mirror_team t
                      where reg.item ->> 'orgId' = $1
                      and reg.item -> 'relevantPackageInfoSnapshot' ->> 'teamIdThatDeterminedPackage' = t.id
                      and reg.item ->> 'orgSeasonId' = $2
                      ${
                        searchTerm
                          ? `and trim(concat(pb.item -> 'virtualAthleteAccount' ->> 'firstName',' ', pb.item -> 'virtualAthleteAccount' ->> 'lastName')) ilike '${searchTerm}' `
                          : ""
                      }
                        and pb.id = reg.item ->> 'playerBundleId') a) b,
               mirror_orgregistrationanswer ans
          where ans.id = b.answer_id) c
    group by player_bundle_id, first_name, last_name, created_at_ms, team_name
    order by ${orderBy}
    offset $3 LIMIT $4;`,
    [p.orgId, p.orgSeasonId, p.offset, p.limit]
  );

  if (res.rowCount === 0) {
    return { data: [], count: 0 };
  }
  return {
    data: res.rows.map(row => {
      return {
        playerBundleId: row["player_bundle_id"] as string,
        firstName: row["first_name"] as string,
        lastName: row["last_name"] as string,
        teamName: row["team_name"] as string,
        answers: row["answers"] as OrgRegistrationAnswer[],
        createdAtMS: parseInt(row["created_at_ms"]) as number
      };
    }),
    count: res.rows[0].count
  };
  // SERVER_ONLY_TOGGLE
}

reports__server__getOrgRegistrationAnswers.auth = async (req: any) => {
  await validateToken(req);
};
